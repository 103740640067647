body {
    font-size:13pt;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #f0f0f0;
}

a {
    color: #0366d6; /* Provide sufficient contrast against white background */
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
