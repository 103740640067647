.product-thumbnail {
    width:100%;
    height:auto;
    max-width:100%;
}

.thumbnail-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive grid with auto columns */
    gap: 16px;
}

@media screen and (max-width: 768px) {
    .thumbnail-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}
