.breadcrumb {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

    .breadcrumb li {
        display: flex;
        align-items: center;
    }

.separator {
    margin: 0 0.5rem;
}
